import {faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button, Grid, Paper, Snackbar, Tab, Tabs, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import tagImage from '../images/tagImage.png';
import Microchip from "../components/Microchip";
import MarengoCopyright from "../components/MarengoCopyright";

export interface IHomePageProps {
}

const HomePage: React.FunctionComponent<IHomePageProps> = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let [tagCode, setTagCode] = useState<string>('');
    let [microchipNumber, setMicrochipNumber] = useState<string>('');
    let [tab, setTab] = useState<number>(0);
    let [searchText, setSearchText] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const goToMicrochipNumber = () => {
        if (/^\d{15}$/.test(searchText)) {
            setMicrochipNumber(searchText);
        } else {
            // Display an error message or handle the invalid input
            setOpenSnackbar(true);
        }
    }

    const goToTagNumber = () => {
        const generateUniqueId: number = Math.floor(Math.random() * 1000000);
        if (tagCode !== '') {
            navigate(`/tag/${tagCode}?tabId=${generateUniqueId}`);
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <div>
            <Paper style={{
                backgroundColor: "#404EED",
                borderBottomRightRadius: 25,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 25,
                color: "#FFFFFF",
                padding: "1rem",
            }}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={3} lg={3}>
                        <Typography fontFamily={"CandyBeans"}>
                            <Button
                                style={{
                                    borderRadius: 10,
                                    fontSize: '1.5rem',
                                    color: "#FFFFFF"
                                }}
                                onClick={() => window.location.href = "https://marengoapp.ca/beta/"}>
                                Marengo
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Tabs value={tab} onChange={handleTabChange} centered>
                            <Tab
                                label="Microchip"
                                style={{
                                    padding: "1rem",
                                    borderRadius: 10,
                                    color: "#FFFFFF",
                                    ...(tab === 0 && {
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                    })
                                }}
                            />
                            <Tab
                                label="Medaille"
                                style={{
                                    padding: "1rem",
                                    borderRadius: 10,
                                    color: "#FFFFFF",
                                    ...(tab === 1 && {
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                    })
                                }}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={0} md={3} lg={3}>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                <Typography variant='h4' fontWeight={"bold"}>{t("have_found_a_pet")}</Typography>
                <br/>
                {tab == 0 && (
                    <div>
                        <Typography variant='h5'>{t("enter_microchip_code")}</Typography>
                        <br/>
                        <Grid container alignContent={"center"} alignItems={"center"} style={{display: "block"}}>
                            <TextField
                                id="tagcode"
                                onChange={(event) => setSearchText(event.target.value)}
                                placeholder="xxxxxxxxxxxxxxx"
                                style={{
                                    background: "#FFFFFF",
                                    borderWidth: "0rem",
                                    borderRadius: 10,
                                    borderColor: "#FFFFFF",
                                    marginRight: "0.75rem"
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={goToMicrochipNumber}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#5b5b5b",
                                    padding: "1rem",
                                    borderRadius: 10
                                }}>
                                <Typography color={"#5b5b5b"}>{t("search")}</Typography>
                            </Button>
                        </Grid>
                    </div>
                )}
                {tab == 1 && (
                    <div>
                        <Typography variant='h5'>{t("enter_tag_code")}</Typography>
                        <br/>
                        <Grid container alignContent={"center"} alignItems={"center"} style={{display: "block"}}>
                            <TextField
                                id="tagcode"
                                onChange={(event) => setTagCode(event.target.value)}
                                placeholder="xxxxxx"
                                style={{
                                    background: "#FFFFFF",
                                    borderWidth: "0rem",
                                    borderRadius: 10,
                                    borderColor: "#FFFFFF",
                                    marginRight: "0.75rem"
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={goToTagNumber}
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#5b5b5b",
                                    padding: "1rem",
                                    borderRadius: 10
                                }}>
                                <Typography color={"#5b5b5b"}>{t("search")}</Typography>
                            </Button>
                        </Grid>
                    </div>
                )}
                <br/>
                <Typography>{t("need_help")}</Typography>
                <FontAwesomeIcon icon={faArrowDown} size='xl'/>
                <br/>
            </Paper>
            {microchipNumber && (
                <Microchip microchipNumber={microchipNumber}/>
            )}
            <div style={{backgroundColor: "#DADADA", padding: "1rem"}}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} lg={6}>
                        <Typography align={"left"} variant='h3' color={"#5b5b5b"} fontWeight={"bold"}>
                            {t("need_help")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <img style={{maxHeight: "20rem", width:"20rem"}} src={tagImage} alt={"Tag"}></img>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Typography color={"#5b5b5b"} variant='h6'>{t("main_page_code_explication")}</Typography>
                    </Grid>
                    <Grid item xs={2} lg={2}>
                    </Grid>
                </Grid>
                <br/>
            </div>
            <br/>
            <br/>
            <Typography variant='h4' color={"#5b5b5b"} fontWeight={"bold"}>{t("ready_to_start")}</Typography>
            <br/>
            <br/>
            <Button
                variant="contained"
                style={{
                    padding: "1rem",
                    borderRadius: 10,
                    marginBottom: "1rem"
                }}
                onClick={() => window.location.href = "https://mgotag.ca/download/"}>
                {t("download_app")}
            </Button>
            <br/>
            <br/>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setOpenSnackbar(false)}
                    sx={{ fontSize: '1.2rem' }}
                    severity="error" // Change the severity as needed (success, error, warning, info)
                >
                    {t("microchipRegexAlert")}
                </Alert>
            </Snackbar>
            <MarengoCopyright />
        </div>
    );
};

export default HomePage;