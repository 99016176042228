import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Card, CardContent, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface ContactInfoState {
    contactList: any[]
}

const ContactInfoCardMicrochip: React.FunctionComponent<ContactInfoState> = (props) => {
    const {t} = useTranslation();

    return (
        <Paper elevation={5} style={{
            margin: '1rem',
            borderRadius: '20px',
        }}>
            <Card
                variant="outlined"
                style={{
                    borderRadius: '20px',
                    padding: '0px',
                }}>
                <CardContent>
                    <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>{t("contacts")}</Typography>
                    <br/>
                    {props.contactList.map((contact, index) => {
                        return (
                            <Grid key={index} alignItems="center" container sx={{ paddingBottom: '1rem' }}>
                                <Grid item={true} xs={2} sm={1} md={1} marginBottom={'10px'}>
                                    <Avatar alt="Profile Picture" src={contact[2].profile_picture_url}/>
                                </Grid>
                                <Grid style={{textAlign: 'left'}} item={true} xs={10} sm={3} md={3}>
                                    <Typography variant='h6'
                                                fontWeight={"bold"}>{contact[1].first_name} {contact[1].name}</Typography>
                                </Grid>
                                <Grid item={true} xs={2} sm={1} md={1} marginBottom={'10px'}>
                                    <FontAwesomeIcon icon={faPhone} fontSize={"2rem"}/>
                                </Grid>
                                <Grid style={{textAlign: 'left'}} item={true} xs={10} sm={2} md={2}>
                                    <Typography variant='h6' color={"#8B8B8B"}>{contact[1].phone_number}</Typography>
                                </Grid>
                                <Grid item={true} xs={2} sm={1} md={1}>
                                    <FontAwesomeIcon icon={faEnvelope} fontSize={"2rem"}/>
                                </Grid>
                                <Grid style={{textAlign: 'left'}} item={true} xs={10} sm={2} md={2}>
                                    <Typography variant='h6' color={"#8B8B8B"}>{contact[0].email}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </CardContent>
            </Card>
        </Paper>


    )
}


export default ContactInfoCardMicrochip;
