import {Grid, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface MarengoCopyrightProps {}

const MarengoCopyright: React.FunctionComponent<MarengoCopyrightProps> = () => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
            <Grid item>
                <Typography variant="body2" color="textSecondary">
                    &copy; {new Date().getFullYear()} Marengo Inc. {t("all_right_reserved")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MarengoCopyright;