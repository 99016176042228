const production = {
  url: {
    API_URL: `https://marengo-prod.herokuapp.com/`
  }
};


const dev = {
  url: {
    API_URL: `http://localhost:8000/`
  }
};

export const config = process.env.NODE_ENV === 'production' ? production : dev;
